import './App.css';
import React from "react"
import { CircularProgress } from "@material-ui/core";
import {
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Area,
  AreaChart,
  Label, Legend
} from 'recharts';



class App extends React.Component {

  state = {
    loading: true,
    error: false,
    data: []
  }

  componentDidMount() {
    this.getHeight();
  }

  getHeight = async () => {
    try {
      let resp = await fetch("/.netlify/functions/waterlevel", {
        method: 'GET',
      })
      let lines = (await resp.text()).split("\n").slice(20);
      // let raw = "2021-05-26 13:25:00,46,3.325\n" +
      //   "2021-06-27 13:30:00,46,3.325\n" +
      //   "2021-06-27 13:35:00,46,3.325\n" +
      //   "2021-06-27 13:40:00,46,3.326\n" +
      //   "2021-06-27 13:45:00,46,3.326\n" +
      //   "2021-06-27 13:50:00,46,3.326\n" +
      //   "2021-06-27 13:55:00,46,3.326\n" +
      //   "2021-06-27 14:00:00,46,3.325\n" +
      //   "2021-06-27 14:05:00,46,3.327\n" +
      //   "2021-06-27 14:10:00,46,3.327\n" +
      //   "2021-06-27 14:15:00,46,3.327\n" +
      //   "2021-06-27 14:20:00,46,3.327\n" +
      //   "2021-06-27 14:25:00,46,3.327\n" +
      //   "2021-06-27 14:30:00,46,3.328\n" +
      //   "2021-06-27 14:35:00,46,3.329\n" +
      //   "2021-06-28 14:40:00,46,3.50"
      // let lines = raw.split("\n")
      let data = []
      for (let i = lines.length - 2; i >= 0; i -= 250) {
        try {
          let l = lines[i].split(',');
          data.push({ date: l[0].substr(0,10), height_m: l[2], height: Math.round(l[2]*3.28*100)/100 })
        } catch {

        }

      }
      data.reverse()
      this.setState({ data: data, loading: false })
    } catch {
      this.setState({ error: true, loading: false })
    }
  }


  toftin = (meters) => {
    console.log(meters)
    var inches = (meters*39.3700787).toFixed(0);
    var feet = Math.floor(inches / 12);
    inches %= 12;
    return { feet, inches }
  }

  majorDisp = (meters) => {
    const {feet, inches} = this.toftin(meters)
    return (
      <div>
        <span style={{ fontSize: '25vw' }}>
          {feet}
        </span>
        <span style={{ fontSize: "10vw"}}>
          ft&nbsp;
        </span>
        <span style={{ fontSize: '15vw' }}>
          {inches}
        </span>
        <span style={{ fontSize: "6vw"}}>
          in
        </span>
        <br/>
        <div style={{ fontSize: "4vw", transform: "translateY(-5vw)"}}>({meters}m)</div>
      </div>
    )
}

  seaDisp = (meters) => {
    const {feet, inches} = this.toftin(meters)
    return (
      <div>
        <span style={{ fontSize: '8vw' }}>
          {feet}
        </span>
        <span style={{ fontSize: "4vw"}}>
          ft&nbsp;
        </span>
        <span style={{ fontSize: '6vw' }}>
          {inches}
        </span>
        <span style={{ fontSize: "2vw"}}>
          in
        </span>
        <br/>
        <div style={{ fontSize: "3vw", transform: "translateY(-1.25vw)"}}>({meters}m)</div>
      </div>
    )
  }

  render() {

    const { data, loading, error } = this.state;

    return (
      <div className="App">
          <h1 style={{ fontSize: "4vw"}}>How High is Marsh Lake?</h1>
          <h4 style={{ fontSize: "2vw", transform: "translateY(-0.5vw)" }}>
            Current water level for &nbsp;
            <a style={{color: "white"}} href="https://www.google.com/maps/place/60%C2%B031'50.0%22N+134%C2%B021'55.0%22W/@60.5294959,-134.3668484,1049m/data=!3m1!1e3!4m5!3m4!1s0x0:0x0!8m2!3d60.5305556!4d-134.3652778?hl=en" target="_balnk">
              Marsh Lake Yukon:
            </a>
          </h4>
          {loading ? (
            <CircularProgress size="8vw" color="white" style={{ marginTop: "2vw"}}/>
          ) : (
            <React.Fragment>
              <div style={{ fontWeight: "bold" }}>
                {this.majorDisp(data[data.length - 1].height_m)}
              </div>
              <div style={{ marginTop: "8vw", fontWeight: "bold" }}>
                <span style={{ fontSize: "3vw" }}>Above Sea Level:</span>
                {this.seaDisp(Number(data[data.length - 1].height_m) + 653.672)}

              </div>

              <div style={{ marginTop: "8vw" }}>
                <span style={{ fontSize: "3vw", fontWeight: "bold" }}>History:</span>
                <div style={{ width: "100%", height: "40vw", margin: "auto", marginTop: "3.5vw", transform: "translateX(-2.5vw)"}}>
                  <ResponsiveContainer width="100%" height="100%" style={{ transform: "translateX(-1vw)" }}>
                    <AreaChart
                      width={500}
                      height={300}
                      data={data}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                      style={{ color: "cornflowerblue", overflow: "visible" }}
                    >
                      <CartesianGrid strokeDasharray="5 10" stoke="#ffffff" strokeWidth={2} fill="white"/>

                      <XAxis stroke="#ffffff" strokeWidth={3} fontSize={18} dataKey="date" angle={-45} tick={{ dy: 45, dx: -30 }}/>
                      <YAxis
                        stroke="#ffffff"
                        strokeWidth={3}
                        fontSize={18}
                        dataKey="height"
                      />
                      <Tooltip itemStyle={{ color: "grey" }}/>
                      <Area type="monotone" dataKey="height" stroke="cornflowerblue" fill="cornflowerblue" fillOpacity={1} strokeWidth={5} dot={false} baseLine={0}/>
                      <ReferenceLine y={10.25} strokeWidth={3} stroke="orange" />
                    </AreaChart>
                  </ResponsiveContainer>
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "90px", transform: "translateX(2.5vw)" }}>
                    <div style={{ width: "2vw", height: "2vw", background: "orange", borderRadius: "100px", marginRight: "0.75vw"}}/>
                    <div style={{ fontSize: "2vw" }}>Average Yearly Maximum</div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
          <div style={{ fontSize: "1.5vw", color: "white", marginTop: "200px" }}>
            Data from the <a style={{ textDecoration: "underline",  }}target="_blank" href="https://wateroffice.ec.gc.ca/report/real_time_e.html?stn=09AB004">Canadian Water Office</a>
          </div>
          <div style={{ fontSize: "1vw", marginTop: "4vw" }}>
            By Cooper Hart 2021
          </div>
      </div>
    );
  }
}

export default App;
